@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,700;1,300;1,400&display=swap');

body {
  margin: 0;
  padding: 30px 0 50px 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #020203 !important;
  background-image: url("../public/assets/vitoria/pattern.webp");
  background-size: 100px auto;
  background-position: top;
  background-repeat: repeat;
}

.typography-pattern {
  position: fixed;
  width: 100%;
  height: 80px;
  left: 0;
  bottom: 0;
  background-image: url("../public/assets/vitoria/pattern-typography-reverse.webp");
  background-size: 600px auto;
  background-position: bottom;
  background-repeat: repeat-x;
  z-index: -1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: #ff652c;
}

@media only screen and (max-width: 767px) {

  body {
    padding-top: 0px;
  }

}